<template>
  <vf-carousel
    v-if="items.length > 0"
    :class-container="{ [`lg:grid lg:cols-2 ${brandClasses.carousel}`]: items?.length > 1 }"
    :loop="gallery.loop"
    data-test-id="vf-media-gallery"
    @paginate="handleChange"
    @scroll-start="handleChange"
    @to-next="handleChange"
    @to-prev="handleChange"
  >
    <template #default="{ activeItem, action }">
      <div
        v-for="(item, i) in items"
        :key="item.src"
        class="group relative w-full"
        :class="{ [brandClasses.carouselItemFirst || '']: i === 0 }"
        :style="{ 'aspect-ratio': `${gallery.presets.src.width} / ${gallery.presets.src.height}` }"
      >
        <template v-if="item.type === 'image'">
          <base-pinch-zoom>
            <vf-zoomer
              v-if="item.srcZoom"
              ref="zoomers"
              :src="item.src"
              :src-zoom="item.srcZoom"
              :alt="item.alt"
              :width="items.length === 1 ? gallery.presets.srcZoom.width : gallery.presets.src.width"
              :height="items.length === 1 ? gallery.presets.srcZoom.height : gallery.presets.src.height"
              :props-img="{ class: 'w-full' }"
              :style="{ 'aspect-ratio': gallery.presets.srcZoom.width / gallery.presets.srcZoom.height }"
              @zoom="$zoomFactor => $emit('zoom', i, $zoomFactor)"
              @swipe="action(activeItem - $event)"
            />
            <base-picture
              v-else
              :src="item.src"
              :alt="item.alt"
              :width="items.length === 1 ? gallery.presets.srcZoom.width : gallery.presets.src.width"
              :height="items.length === 1 ? gallery.presets.srcZoom.height : gallery.presets.src.height"
              :lazy="i >= 4"
              class="w-full"
            />
          </base-pinch-zoom>

          <slot v-bind="{ i }" />

          <base-button
            v-if="$feature.showShopSimilar && i === 0 && syteReady"
            class="syte-discovery absolute bottom-0 left-0 z-2 mb-2 ml-2 flex focus:op-100"
            :sku="productId"
            :data-image-src="item.src"
            data-test-id="vf-media-gallery-shop-similar"
            data-placement="PDP-main"
          >
            <vf-icon name="objective" size="md" />
            <span class="ml-2 text-md self-center text-sm fw-bold">
              {{ $t.shopSimilar }}
            </span>
          </base-button>
        </template>

        <base-video
          v-if="item.type === 'video'"
          ref="videos"
          v-bind="item"
          :controlslist
          class="absolute-0 full bg-black"
          @play="handlePlay(item)"
        />
      </div>
    </template>

    <template #prev="{ active, action }">
      <base-button
        class="absolute left-0 top-1/2 z-2 duration -mt-4 lg:hidden -scale-x-100"
        :class="[{ 'pointer-events-none op-0': !gallery.loop && !active, 'hidden': !showPrevNext || items.length < 2 }]"
        :aria-label="$t.previous"
        data-test-id="vf-carousel-arrow-left"
        @click.stop="action"
      >
        <vf-icon name="chevron" size="lg" />
      </base-button>
    </template>

    <template #next="{ active, action }">
      <base-button
        class="absolute right-0 top-1/2 z-2 duration -mt-4 lg:hidden"
        :class="[{ 'pointer-events-none op-0': !gallery.loop && !active, 'hidden': !showPrevNext || items.length < 2 }]"
        :aria-label="$t.next"
        data-test-id="vf-carousel-arrow-right"
        @click.stop="action"
      >
        <vf-icon name="chevron" size="lg" />
      </base-button>
    </template>

    <template v-if="showPagination" #pagination="{ activeItem, action, pages }">
      <base-scroll
        v-if="thumbnails"
        class-container="flex pt-4 space-x-2 lg:hidden wrap"
        data-test-id="vf-media-gallery-pagination"
      >
        <div
          v-for="(item, i) in items"
          :key="i"
          class="w-12 flex cursor-pointer center b b-transparent"
          :class="{ '!b-grey-10': i === activeItem, 'relative': item.type === 'video' }"
          :style="{ 'aspect-ratio': gallery.presets.src.width / gallery.presets.src.height }"
          :aria-current="i === activeItem"
          @click="action(i)"
        >
          <base-picture :src="item.type === 'video' ? item.poster! : item.src" />
          <vf-icon v-if="item.type === 'video'" name="play" class="absolute" />
        </div>
      </base-scroll>
      <vf-media-gallery-pagination
        v-else-if="pagination && pages"
        :pages
        :active-page="activeItem"
        :class="brandClasses.pagination"
      />
    </template>
  </vf-carousel>
  <div v-else class="bg-grey-80 py-1/2 text-center lh-0">
    {{ $t.imageUnavailable }}
  </div>
</template>

<script lang="ts" setup>
import type { ImageItem, VideoItem } from '#types/media'
import type { BaseVideo as BaseVideoType, VfZoomer as VfZoomerType } from '#components'

defineProps<{
  /**
   * List of configs that will be used to render slides and thumbnails
   */
  items: (ImageItem | VideoItem)[]
  /**
   * Defines whether pagination dots should be displayed under carousel. Tablet/Mobile only
   */
  pagination?: boolean
  /**
   * Defines whether thumbnails should be displayed under carousel. This property is an alternative for "pagination" and has higher priority. Tablet/Mobile only
   */
  thumbnails?: boolean
  productId?: string
}>()

defineEmits<{
  zoom: [index: number, zoomFactor: number]
}>()

const { brandClasses, controlslist, showPagination, showPrevNext } = useAppConfig().components.vf.mediaGallery
const { gallery } = useAppConfig().pages?.pdp || {}
const { $feature, $viewport } = useNuxtApp()
const { init: initSyte, ready: syteReady } = useSyte()

if ($feature.showShopSimilar) initSyte()

const videos = ref<typeof BaseVideoType[]>([])
const zoomers = ref<typeof VfZoomerType[]>([])

const handleChange = () => {
  videos.value.forEach((video) => video.pause())
  zoomers.value.forEach((zoomer) => zoomer.resetZoom())
}
const handlePlay = ({ src }) => videos.value.forEach(
  (video) => getValueForBreakpoint($viewport.breakpoint, video.src) !== src && video.pause()
)
</script>
