<template>
  <div data-test-id="form-sign-up">
    <vf-notification
      v-if="notification.message"
      data-scroll-el="sign-up-notification"
      class="mb-4"
      :type="notification.type"
      :dismissible="false"
    >
      {{ notification.message }}
    </vf-notification>
    <template v-if="!compact">
      <h2 :class="brandClasses.heading">
        {{ isLoyaltySignUp ? $t.loyaltySignUpForm.header : $t.createAccount }}
      </h2>
      <p
        v-if="$feature.enableLoyaltyFeatures"
        class="mb-4 mt-1 "
        :class="{ 'fw-bold uppercase': !isLoyaltySignUp }"
        style="padding-right: 25%;"
      >
        {{ isLoyaltySignUp ? $t.loyaltySignUpForm.subheader : $t.joinCommunity }}
      </p>
      <p :class="brandClasses.helpText">
        {{ $t.requiredField }}
      </p>
    </template>
    <base-form
      ref="formRef"
      :form="form"
      class="grid gap-4"
      @submit="() => isLoyaltySignUp ? loyaltySubmit() : submit()"
    >
      <template v-for="fieldName in fieldsOrder" :key="fieldName">
        <template v-if="mode !== 'simplified'">
          <vf-form-field
            v-if="fieldName === 'firstName'"
            v-slot="{ attrs }"
            name="firstName"
            :rule="[...getValidator('firstName', $t.firstName)]"
          >
            <vf-input
              v-model="form.firstName"
              v-bind="attrs"
              :disabled="!!(isFormPrepopulated && form.firstName)"
              required
            >
              {{ $t.firstName }}
            </vf-input>
          </vf-form-field>
          <vf-form-field
            v-if="fieldName === 'lastName'"
            v-slot="{ attrs }"
            name="lastName"
            :rule="[...getValidator('lastName', $t.lastName)]"
          >
            <vf-input
              v-model="form.lastName"
              v-bind="attrs"
              :disabled="!!(isFormPrepopulated && form.lastName)"
              required
            >
              {{ $t.lastName }}
            </vf-input>
          </vf-form-field>
          <vf-form-field
            v-if="fieldName === 'birthDate' && showBirthDateField"
            v-slot="{ attrs }"
            name="birthDate"
            :rule="[
              ...(isBirthDateRequired ? [validateRequired($t.birthDate)] : []),
              validateDate(),
              validateMinDate(minBirthDate, $t.dobValidationMsg),
              validateMaxDate(maxBirthDate, $t.dobAgeRestrictionMsg),
            ]"
            :hint="$t.dateFormatHint"
          >
            <input-date
              v-model="form.birthDate"
              v-bind="attrs"
              :display-format="dateDisplayFormat"
              :disabled="!!(isFormPrepopulated && form.birthDate)"
              :required="isBirthDateRequired"
            >
              <template #end>
                <vf-toggletip placement="top-end">
                  <template #trigger>
                    <span class="sr-only">{{ $t.birthDate }}</span>
                    <vf-icon name="info" size="md" />
                  </template>
                  {{ $t.birthDateTooltip }}
                </vf-toggletip>
              </template>
            </input-date>
          </vf-form-field>
          <vf-form-field
            v-if="fieldName === 'zipCode'"
            v-slot="{ attrs }"
            name="postalCode"
            :rule="[...getValidator('postalCode', $t.zipCode, {
              pattern: $t.validation.postalCode,
              minLength: $t.validation.postalCodeLength,
            })]"
          >
            <vf-input v-model="form.postalCode" v-bind="attrs" required>
              {{ $t.zipCode }}
            </vf-input>
          </vf-form-field>
          <template v-if="fieldName === 'phone'">
            <vf-form-field
              v-slot="{ attrs }"
              name="phone"
              :rule="[
                ...isMobilePhoneRequired ? [validateRequired($t.mobileNumber)] : [],
                validatePhone(form.phoneCode),
              ]"
            >
              <input-phone
                v-model="form.phone"
                v-model:code="form.phoneCode"
                v-bind="attrs"
                :disable-code-select="mobilePhone?.enableCountryCodeSelection !== true && !isFormPrepopulated"
                :required="isMobilePhoneRequired"
                use-country-code
              >
                {{ $t.mobileNumber }}
                <template #end>
                  <vf-toggletip placement="top-start">
                    <template #trigger>
                      <span class="sr-only">{{ $t.mobileNumber }}</span>
                      <vf-icon name="info" size="md" />
                    </template>
                    {{ $t.mobileNumberTooltip }}
                  </vf-toggletip>
                </template>
              </input-phone>
            </vf-form-field>
            <vf-form-field
              v-if="showSmsSection"
              v-slot="{ attrs }"
              name="optInAccepted"
              :rule="isSmsOptInRequired ? [validateRequired('', $t.validators.requiredCheckbox)] : []"
            >
              <vf-checkbox
                v-model="form.optInAccepted"
                v-bind="attrs"
                :size="smsCheckboxSize"
                :disabled="!isPhoneValid"
                :required="isSmsOptInRequired"
                class="text-md !text-base"
                @change="handleOptInCheckbox"
              >
                {{ $t.smsCheckboxTerms }}
              </vf-checkbox>
            </vf-form-field>
            <div v-if="showSmsSection && isShowSmsTerms" class="fw-light space-y-4">
              <form-legal-disclaimer :content="$t.smsTerms" />

              <vf-button :loading class="w-full" @click="handleAcceptOptIn()">
                {{ $t.iAccept }}
              </vf-button>
            </div>
          </template>
          <vf-form-field
            v-if="isGenderRequired && fieldName === 'gender'"
            v-slot="{ attrs }"
            name="gender"
            :rule="validateRequired('', $t.validators.requiredSelect)"
          >
            <vf-select
              v-model="form.gender"
              v-bind="attrs"
              :options="genderOptions"
              :disabled="!!(isFormPrepopulated && form.gender)"
              required
            >
              {{ $t.gender }}
            </vf-select>
          </vf-form-field>
        </template>
        <vf-form-field
          v-if="fieldName === 'email'"
          v-slot="{ attrs }"
          name="email"
          :rule="[...getValidator('email', $t.email), validateEmail()]"
          :hint="$t.emailFormatHint"
        >
          <vf-input
            v-model="form.email"
            v-bind="attrs"
            type="email"
            :disabled="disableEmail || !!(isFormPrepopulated && form.email)"
            required
            @change="handleEmailChange"
          >
            {{ $t.email }}
          </vf-input>
        </vf-form-field>
        <vf-form-field
          v-if="!isLoyaltySignUp && fieldName === 'password'"
          v-slot="{ attrs }"
          class="space-y-2"
          name="password"
          :rule="!isLoyaltySignUp ? [validateRequired($t.password), validatePassword()] : []"
        >
          <input-password v-model="form.password" v-bind="attrs" aria-describedby="password-requirements" required>
            {{ $t.password }}
          </input-password>
        </vf-form-field>
      </template>
      <validator-password
        v-if="!isLoyaltySignUp"
        id="password-requirements"
        :password="form.password"
        :class="brandClasses.passwordRequirements"
      />
      <div :class="brandClasses.checkboxesWrapper" class="ws-pre-wrap">
        <template
          v-for="
            item in ((isLoyaltySignUp && loyaltyAgreementCheckboxes) || agreementCheckboxes) as AgreementCheckbox[]
          "
          :key="item.type"
        >
          <vf-form-field
            v-if="(!(item.showCheckboxInBenefitsSection || []).includes(locale)) && (!('locales' in item)
              || (item.locales || []).includes(locale))"
            v-slot="{ attrs }"
            :name="item.type"
            :rule="('required' in item && item.required) ? [validateRequired('', $t.validators.requiredCheckbox)] : []"
          >
            <vf-checkbox v-model="form[item.type]" v-bind="attrs" :required="item.required">
              <form-legal-disclaimer
                :content="$t.brandAgreement[item.type]"
                :data-test-ids="{ 'loyalty-terms': 'form-sign-up-link-loyalty' }"
              />
            </vf-checkbox>
          </vf-form-field>
        </template>
      </div>
      <loyalty-benefits v-if="$feature.showLoyaltyBenefitsInSignUp">
        <template #loyalty-terms>
          <template
            v-for="
              item in ((isLoyaltySignUp && loyaltyAgreementCheckboxes) || agreementCheckboxes) as AgreementCheckbox[]
            "
            :key="item.type"
          >
            <vf-form-field
              v-if="(item.showCheckboxInBenefitsSection || []).includes(locale)"
              v-slot="{ attrs }"
              :name="item.type"
              :rule="('required' in item && item.required) ? [validateRequired('', $t.validators.requiredCheckbox)] : []"
            >
              <vf-checkbox v-model="form[item.type]" v-bind="attrs" :required="item.required">
                <form-legal-disclaimer
                  :content="$t.brandAgreement[item.type]"
                  :data-test-ids="{ 'loyalty-terms': 'form-sign-up-link-loyalty' }"
                />
              </vf-checkbox>
            </vf-form-field>
          </template>
        </template>
      </loyalty-benefits>
      <vf-button type="submit" :size="buttonSize" :loading :class="brandClasses.submitButton">
        {{ isLoyaltySignUp ? $t.loyaltySignUpForm.submit : $t.createAccountSubmit }}
      </vf-button>
      <div v-if="showSignInCta && insideDialog" class="mt-6 flex items-center gap-2 text-sm">
        {{ $t.alreadyAMember }}
        <vf-link @click="openDialogSignIn">
          {{ $t.signIn }}
        </vf-link>
      </div>
      <!-- espot: below-sign-up -->
      <cms-section v-if="eSpot" :section="eSpot" class="pt-10 lg:mt-8 md:ml-4 md:mt-6" lazy-media />
    </base-form>
  </div>
</template>

<script lang="ts" setup>
import type { BaseForm as BaseFormType } from '#components'
import { ApiErrorCode } from '#root/enums/api'
import type { FormLocation } from '#types/gtm'
import type { BaseNotification } from '#types/notification'
import type { AgreementCheckbox } from '#types/config/components/form/signUp'

const {
  compact,
  email,
  eSpot,
  disableEmail,
  formLocation = 'modal:single:none',
  isLoyaltySignUp,
  mode,
  disableModal,
  insideDialog
} = defineProps<{
  compact?: boolean
  disableEmail?: boolean
  email?: string
  eSpot?: any
  formLocation?: FormLocation
  mode?: 'simplified'
  isLoyaltySignUp?: boolean
  insideDialog?: boolean
  disableModal?: boolean
}>()

const emit = defineEmits<{
  submit: [newsletterConsent?: boolean]
  resolve: []
  signIn: []
}>()

const { load: loadRules, getValidator } = useAddressApiRules()
const { authentication, consumer } = useApi()
const { collectInterestsAtSignUp } = useFeatureFlags()
const {
  agreementCheckboxes: agreementCheckboxesConfig,
  brandClasses,
  buttonSize,
  fieldsOrder,
  gender,
  loyaltyAgreementCheckboxes,
  minimalAge,
  mobilePhone,
  showBirthDateField,
  showSmsSectionLocales,
  smsCheckboxSize,
  isSmsOptInRequired,
  isBirthDateRequired
} = useAppConfig().components.form.signUp
const {
  isInterestsDependingOnNewsletter,
  showSignInCta
} = useAppConfig().components.dialog.signUp
const { order } = useCheckoutStore()
const countryCode = useCountryCode()
const { $gtm, $t } = useNuxtApp()
const { DialogInterests, DialogSignIn } = useDialogsStore()

const {
  validateDate,
  validateEmail,
  validatePassword,
  validatePhone,
  validateRequired,
} = useLocalizedValidators()
const profile = useProfileStore()
const recaptcha = useRecaptcha()
const route = useRoute()
const subscriptions = useSubscriptions()
const locale = useLocale()

const agreementCheckboxes = ref(agreementCheckboxesConfig)
const isFormPrepopulated = ref(false)
const formRef = ref<InstanceType<typeof BaseFormType>>()
const loading = ref(true)
const minBirthDate = new Date('01/01/1900')
const maxBirthDate = ref()
const dateDisplayFormat = useRegion() === 'EMEA' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
const genderOptions = gender?.options.map((item) => ({ value: item.value, label: $t.genderOptions[item.label] })) ?? []
const isGenderRequired = gender && (!gender.requiredLocales || gender.requiredLocales.includes(locale))
const isMobilePhoneRequired = mobilePhone?.requiredLocales?.includes(locale)
const showSmsSection = showSmsSectionLocales?.includes(locale)
const isSmsOptInAccepted = ref(false)
const isShowSmsTerms = ref(false)

const { billingAddress = {} as typeof order['billingAddress'] } = order

let enableGtmClose = true

const {
  firstName: profileFirstName,
  lastName: profileLastName,
  email: profileEmail,
  phone: profilePhone,
  birthDate: profileBirthdate,
  gender: profileGender,
  postalCode: profilePostalCode,
} = profile.details || {}

const notification = reactive<BaseNotification>({ message: '', type: '' })
const form = reactive({
  firstName: billingAddress.firstName || '',
  lastName: billingAddress.lastName || '',
  birthDate: '',
  phone: parseE164Phone(billingAddress.phone ?? '')?.[1] || '',
  phoneCode: getPhoneCodeByCountryCode(countryCode) || '',
  email: email || billingAddress.email || '',
  password: '',
  newsletter: false,
  loyaltyTerms: false,
  brandTerms: false,
  policy: false,
  postalCode: '',
  ...(isGenderRequired && { gender: '' }),
  ...isLoyaltySignUp && {
    firstName: profileFirstName,
    lastName: profileLastName,
    email: profileEmail,
    phone: parseE164Phone(profilePhone || '')?.[1] || '',
    birthDate: profileBirthdate,
    postalCode: profilePostalCode,
    ...(isGenderRequired && { gender: profileGender || '' })
  },
  optInAccepted: false
})

// Retail Enrollment, will preload the consumer information for sign up form
onMounted(async () => {
  loadRules(countryCode.toLocaleLowerCase(), locale)
  /*
  * `consumerId` and `enrolledEmail` are typed as `LocationQueryValue | LocationQueryValue[]`.
  * `LocationQueryValue` is defined as `string | null`. We're crossing out null with an if,
  * and in our case it's impossible for those values to be an array.
  * To not make any TS gymnastics we simply ignore the error.
  * */
  const { consumerId, email: enrolledEmail } = route.query
  if ([!isLoyaltySignUp, consumerId, enrolledEmail].every(Boolean)) {
    const { consumerDetails, enrollments } = await consumer.$retrieveUserData({
      consumerId: consumerId as string,
      email: enrolledEmail as string
    })

    const { birthDate, email, firstName, lastName, phone, gender } = consumerDetails
    const [phoneCode, enrolledPhone] = parseE164Phone(phone ?? '')
    form.birthDate = birthDate
    form.email = email
    form.firstName = firstName
    form.lastName = lastName
    form.phone = enrolledPhone
    form.phoneCode = phone ? phoneCode : getPhoneCodeByCountryCode(countryCode) || ''
    form.gender = gender
    isFormPrepopulated.value = true
    if (enrollments.some(({ type, doubleOptin }) => type === 'Loyalty' && doubleOptin)) {
      form.loyaltyTerms = true
      agreementCheckboxes.value = (agreementCheckboxesConfig as AgreementCheckbox[]).filter(({ type }) => type !== 'loyaltyTerms')
    }
  }
  else if (isLoyaltySignUp) {
    isFormPrepopulated.value
        = [profileFirstName, profileLastName, profileEmail, profilePhone, profileBirthdate, profileGender].some(Boolean)
  }
  $gtm.push('authForm.onImpression', 'Registration', formLocation)

  const today = await getServerDate()
  maxBirthDate.value = new Date(today.getTime())
  maxBirthDate.value.setFullYear(today.getUTCFullYear() - minimalAge)
  loading.value = false
})

const handleEmailChange = () => {
  $gtm.push('authForm.onEmail', 'Registration', formLocation)
}

const getExtraLoyaltyAttrs = () => ({
  loyaltyEnrollment: 'Full',
  loyaltyNewsletterConsent: true
})

const isPhoneValid = computed(() => form.phone && !formRef.value?.fields.phone.invalid)

const handleOptInCheckbox = () => {
  form.optInAccepted = false
  isShowSmsTerms.value = true
}
const handleAcceptOptIn = () => {
  form.optInAccepted = true
  isSmsOptInAccepted.value = true
  isShowSmsTerms.value = false
  formRef.value?.validate()
}

watch(isPhoneValid, (isValid) => {
  if (!isValid) {
    form.optInAccepted = false
    isShowSmsTerms.value = false
  }
})

async function submit() {
  notification.message = ''
  loading.value = true

  try {
    const {
      phone,
      phoneCode,
      policy: isTermsConfirmed,
      newsletter: newsletterConsent,
      loyaltyTerms: loyaltyConsent,
      birthDate,
      ...restParams
    } = form

    const formParams = mode === 'simplified'
      ? {
          email: form.email,
          password: form.password
        }
      : {
          ...(birthDate && { birthDate }),
          ...restParams,
          ...(phone && { phone: formatE164phone(phone, phoneCode), phoneCode }) // When the country doesn't require phone number and mobile field is blank
        }

    $gtm.push('authForm.onRegistrationSubmit', formLocation, 'ecom')
    $gtm.push('authForm.onLoginSubmit', formLocation, true)

    const captchaResponse = await recaptcha.execute('register')
    await authentication.$signUp({
      // form data
      ...formParams,
      isTermsConfirmed,
      isTemporaryPassword: true,
      subscriptions: {
        newsletterConsent,
        loyaltyConsent,
        ...(loyaltyConsent && getExtraLoyaltyAttrs())
      },
      source: {
        store: route.query.storeNum ? route.query.storeNum.toString() : useSiteId(),
        acquisitionType: 'Registered',
        campaignName: route.query.enrollment_marketing_source as string
      },
      recaptcha_response: captchaResponse,
      ...(loyaltyConsent && !isTermsConfirmed && { termsConditionsAndPolicy: true })
    }, {
      onRequest: (context) => {
        if (route.query.leadSource) {
          context.options.headers = {
            ...context.options.headers,
            source: route.query.leadSource.toString()
          }
        }
      }
    })

    notification.message = $t.accountCreated
    notification.type = 'success'

    // optin user to sms after successful registration
    if (isSmsOptInAccepted.value) {
      await subscriptions.sms(
        formatLocalNumberToE164(form.phone),
        form.phoneCode,
        true
      )
    }

    // sign in the user after a successful registration
    const { login } = useAuthStore()
    const token = await recaptcha.execute('login')
    await login(form.email, form.password, token)

    notification.message = $t.youHaveSuccessfullyLoggedIn

    if (route.query.referral_code) {
      const xUsidCookie = useCookie('x-usid')
      await consumer.$getLoyaltyToken({
        usid: xUsidCookie.value || uuid(),
        referralCode: route.query.referral_code as string,
      })
    }

    await profile.get()
    // moved it here so the Sign Up modal closes earlier
    emit('submit', newsletterConsent)

    $gtm.push('user.onLoadUserData', await getUserEventData())
    $gtm.push('authForm.onRegistrationConfirmed', formLocation, 'ecom', 'Standard', false)
    $gtm.push('authForm.onLoginConfirmed', formLocation, 'Standard', true)

    if (collectInterestsAtSignUp && (!isInterestsDependingOnNewsletter || newsletterConsent))
      DialogInterests.open()
  }
  catch (err) {
    assertApiError(err)

    switch (err.errorId) {
      case ApiErrorCode.LOGIN_IN_USE:
      case ApiErrorCode.LOGIN_IN_USE2:
        formRef.value!.invalidate('email', $t.apiMessages[err.errorId], true)
        break

      case ApiErrorCode.PHONE_NUMBER_ALREADY_IN_USE:
      case ApiErrorCode.PHONE_NUMBER_ALREADY_IN_USE2:
        formRef.value!.invalidate('phone', $t.apiMessages[err.errorId], true)
        break

        // in case of missing birthdate need to trigger validation on the form
      case ApiErrorCode.MISSING_BIRTHDATE:
        formRef.value?.validate()
        break

      default:
        notification.message = err.message
        notification.type = 'error'

        await nextTick()

        scrollToElement('sign-up-notification')
    }
  }
  finally {
    loading.value = false
  }
}

async function loyaltySubmit() {
  notification.message = ''
  loading.value = true

  try {
    const {
      firstName,
      lastName,
      phone,
      phoneCode,
      birthDate,
      gender
    } = form

    const data = {
      consumerProfile: {
        consumerDetails: {
          ...(!profileFirstName && { firstName }),
          ...(!profileLastName && { lastName }),
          ...(!profilePhone && phone && { phone: formatE164phone(phone, phoneCode) }),
          ...(!profileBirthdate && { birthDate }),
          ...(!profileGender && gender && { gender })
        },
        enrollments: [
          {
            type: 'Loyalty',
            optin: true,
            doubleOptin: true,
            region: useRegion(),
            channel: 'email'
          }
        ]
      }
    }

    await consumer.$updateProfile(data)

    await profile.get()

    emit('submit')
  }
  catch (err) {
    assertApiError(err)
    // in case of missing birthdate need to trigger validation on the form
    if (err.errorId === ApiErrorCode.MISSING_BIRTHDATE) {
      formRef.value?.validate()
    }
    else {
      notification.message = err.message
      notification.type = 'error'
    }
  }
  finally {
    loading.value = false
  }
}

const openDialogSignIn = async () => {
  enableGtmClose = false
  emit('signIn')

  if (!disableModal) {
    DialogSignIn.open()
    // When we open the sign-in, close the sign-up
    emit('resolve')
  }
}

onUnmounted(() => {
  if (enableGtmClose) $gtm.push('authForm.onRegistrationClose', 'modal:single:none', 'ecom')
})
</script>
