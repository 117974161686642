<template>
  <div class="bg-grey-90 pb-4 pt-10 dark:bg-black lg:pb-8 lg:pt-14">
    <h2 v-if="content.title" class="elevate-title-4 mx-4 c-grey-20 lg:mx-10 dark:c-white">
      {{ content.title }}
    </h2>
    <client-only>
      <vf-carousel
        v-if="recommendedProducts?.length"
        class-container="scroll-px-4 lg:scroll-px-10"
        class-controls="mx-10 rounded-full bg-white c-grey-10 p-2 op-0 shadow-sm duration lg:parent-hover:op-100"
        class-wrapper="parent"
        class="lg:mt-2"
      >
        <div
          v-for="({ recToken, ...card }, i) in recommendedProducts"
          :key="`${card.id}-${i}`"
          v-style="{
            ml: i === 0 && { sm: '0.75rem', lg: '2.25rem' },
            mr: i === recommendedProducts.length - 1 && { sm: '0.75rem', lg: '2.25rem' },
            w: slideWidth[content.variant || '3-cards'],
          }"
          class="px-1 py-6"
          @click="sendProductClickImpression(recToken)"
        >
          <product-card-recommended-elevate v-bind="card" :currency="currency[locale]" />
        </div>
      </vf-carousel>
      <template #fallback>
        <div class="grid mt-4 gap-2 px-4 lg:cols-3 lg:mt-6 lg:px-10 md:cols-2">
          <div class="aspect-square skeleton" />
          <div class="aspect-square skeleton <md:hidden" />
          <div class="aspect-square skeleton <lg:hidden" />
        </div>
      </template>
    </client-only>
  </div>
</template>

<script lang="ts" setup>
import type { ProductRecommendations } from '#types/components/cms/product-recommendations'
import type { MonetateExtractedProduct } from '#types/p13n'

const props = defineProps<{
  content: ProductRecommendations
}>()

const locale = useLocale()
const { extractProduct, sendMonetateEvents, sendRecImpressionsEvent } = useMonetate()
const { $gtm } = useNuxtApp()
const { currency } = useRuntimeConfig().public

const slideWidth = {
  '3-cards': {
    sm: '86.96%',
    md: '44.44%',
    lg: '30.77%'
  },
  '6-cards': {
    sm: '43.48%',
    md: '22.22%',
    lg: '15.385%'
  }
}

const recommendedProducts = computed<MonetateExtractedProduct[]>(
  () => props.content.recommendedProducts?.map((product) => extractProduct(product, props.content.showRatings))
)

const sendProductClickImpression = (recToken: string) => {
  const clickedRecommendation = props.content.recommendedProducts.find((product) => product.recToken === recToken)

  sendMonetateEvents([{
    eventType: 'monetate:record:RecClicks',
    recClicks: [recToken]
  }])

  if (clickedRecommendation) {
    $gtm.push('product.onProductRecClick', clickedRecommendation, {
      action: 'Click Recommendation',
      currency: currency[locale],
      experience: props.content.monetateDecision,
      list: 'Product Recommendation Carousel',
      title: props.content.title
    })
  }
}

onMounted(() => {
  if (props.content.monetateDecision?.name !== 'Control' && props.content.recommendedProducts?.length) {
    sendRecImpressionsEvent(props.content.recommendedProducts, 24)
    $gtm.push('product.onProductRecImpressions', props.content.recommendedProducts, {
      currency: currency[locale],
      experience: props.content.monetateDecision,
      list: 'Product Recommendation Carousel',
      title: props.content.title
    })
  }
})
</script>
