<template>
  <nav :aria-label="$t.utilityNavigationLabel" class="text-xs lh-2">
    <client-only>
      <vf-link
        v-if="$feature.showSignInLinkMobileOnTop && !auth.loggedIn"
        class="lg:hidden"
        variant="quiet"
        to="/sign-in"
        role="button"
        @click.prevent.capture="handleSignIn"
      >
        {{ $t.signIn }}
      </vf-link>
    </client-only>
    <vf-location-selector @click="handleClick($t.locationSelector)" />
    <template v-for="({ type, to, title }, key) in links" :key>
      <base-button
        v-if="type === 'chat' && $feature.enableChat"
        data-chat="utility-nav"
        class="hover:underlined hover:c-grey-30"
        @click="handleChat(title)"
      >
        {{ title }}
      </base-button>
      <vf-link
        v-else-if="type !== 'chat'"
        variant="quiet"
        :to
        :target="to.includes('http') ? '_blank' : undefined"
        @click="handleClick(title)"
      >
        {{ title }}
      </vf-link>
    </template>
    <vf-link
      v-if="giftCardLocales.includes($locale)"
      variant="quiet"
      to="/gift-card"
      @click="handleClick($t.giftCardUtilityNavigation)"
    >
      {{ $t.giftCardUtilityNavigation }}
    </vf-link>
    <vf-link
      v-if="$feature.allowFavorites"
      variant="quiet"
      to="/favorites"
      class="flex center gap-1"
      @click="handleClick($t.favorites)"
    >
      {{ $t.favorites }}
      <span
        v-if="favoritesStore.favorites?.length"
        class="pointer-events-none h-4 w-4 flex select-none center b-2 b-white rounded-full bg-brand-2 c-white"
        style="font-size: 0.5rem;"
      >
        {{ favoritesStore.favoritesCount }}
      </span>
    </vf-link>
    <client-only>
      <template v-if="auth.loggedIn">
        <vf-link
          v-if="enableLoyaltyUtilityNav"
          variant="quiet"
          :to="profileStore.profile?.isLoyaltyMember ? '/account/loyalty' : '/loyalty'"
          @click="handleClick($t.loyaltyUtilityNavLabel)"
        >
          {{ $t.loyaltyUtilityNavLabel }}
        </vf-link>
        <vf-link variant="quiet" to="/account" @click="handleClick($t.myAccount)">
          {{ $t.myAccount }}
        </vf-link>
        <base-button class="hover:underlined" @click="handleSignOut">
          {{ $t.signOut }}
        </base-button>
        <loyalty-rewards-preview-tile v-if="showLoyaltyRewardsInUtilityNav" class="<lg:hidden" />
      </template>
      <template v-else>
        <vf-link
          v-if="enableLoyaltyUtilityNav"
          variant="quiet"
          to="/loyalty"
          @click="handleClick($t.earnRewards)"
        >
          {{ $t.earnRewards }}
        </vf-link>
        <vf-link :class="{ '<lg:hidden': $feature.showSignInLinkMobileOnTop }" variant="quiet" to="/sign-in" role="button" @click.prevent.capture="handleSignIn">
          {{ $t.signIn }}
        </vf-link>
      </template>
    </client-only>
  </nav>
</template>

<script lang="ts" setup>
import type { NavigationLink } from '#types/navigation'

defineProps<{
  links: NavigationLink[]
}>()

const emit = defineEmits<{
  /**
   * Emits when a navigation item is clicked
   */
  click: []
}>()

const auth = useAuthStore()
const profileStore = useProfileStore()
const { DialogSignIn } = useDialogsStore()
const favoritesStore = useFavoritesStore()
const { $chat, $gtm, $t } = useNuxtApp()
const { giftCardLocales } = useAppConfig().components.vf.utilityNavigation

const { enableLoyaltyUtilityNav, showLoyaltyRewardsInUtilityNav } = useFeatureFlags()

const handleClick = (title: string) => {
  emit('click')
  $gtm.push('topNav.onClickUtilityLink', fullCapitalize(title))
}

const handleChat = (title: string) => {
  handleClick(title)
  $chat.open()
}

const handleSignIn = async () => {
  handleClick($t.signIn)
  // Workaround until the bug in radix-vue is fixed
  // https://github.com/radix-vue/radix-vue/issues/539
  await nextTick()
  DialogSignIn.open()
}

const handleSignOut = () => {
  handleClick($t.signOut)
  auth.logout()
}
</script>
