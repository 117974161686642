<template>
  <vf-input
    v-bind="{ disabled, invalid, required, variant }"
    data-test-id="input-phone"
  >
    <slot>{{ $t.mobileNumber }}</slot>
    <template #input>
      <base-input
        ref="el"
        v-bind="{ disabled, placeholder, required }"
        v-model="phone"
        type="tel"
        @blur="$emit('change', $event)"
        @change="$emit('change', $event)"
      />
    </template>
    <template v-if="!disableCodeSelect" #select>
      <span>
        <base-select
          v-model="localCode"
          :options="options"
          :label-formatter="labelFormatter"
          :disabled
          :aria-label="$t.mobileCountryCode"
          :class="[brandClasses.text, { 'bg-grey-90 b-grey-50': disabled }]"
          data-test-id="form-input-phone-select"
        />
        <vf-icon name="chevron" dir="down" />
      </span>
    </template>
    <template #end>
      <slot name="end" />
    </template>
  </vf-input>
</template>

<script lang="ts" setup>
import type { SelectOption } from '#types/components/base/select'
import type { PhoneCode } from '#types/config/phoneCode'
import type { CountryCode } from '#types/locale'

const props = defineProps<{
  placeholder?: string
  disabled?: boolean
  disableCodeSelect?: boolean
  invalid?: boolean
  required?: boolean
  variant?: 'inverse'
  useCountryCode?: boolean
  addressCountryCode?: string
}>()

defineEmits<{ change: [value: string] }>()

const { brandClasses } = useAppConfig().components.vf.form.select
const phoneCodes = useRuntimeConfig().public.phoneCodes as PhoneCode[]

const model = defineModel<string>({ required: true })
const code = defineModel<string>('code')

const countryCode = computed<Uppercase<CountryCode>>(
  () => code.value
    ? getCountryCodeByPhoneCode(
      code.value,
      props.addressCountryCode || useCountryCode()
    ) as Uppercase<CountryCode>
    : useCountryCode()
)

const codes = phoneCodes.map(({ name, dialCode }) => ({
  label: `${name} (+${dialCode})`,
  value: dialCode
}))

const codeCounts = phoneCodes.reduce((acc, { dialCode }) => {
  if (!acc[dialCode]) acc[dialCode] = 0
  acc[dialCode]++
  return acc
}, {})

const codesWithCountryCode = phoneCodes.map(({ name, dialCode, code }) => ({
  label: `${name} (+${dialCode})`,
  value: codeCounts[dialCode] > 1 ? `${dialCode}-${code}` : dialCode,
  dialCode
}))

const options = props.useCountryCode ? codesWithCountryCode : codes

const labelFormatter = (v?: SelectOption) => `+${(v?.value as string).split('-')[0] || ''}`

const localCode = computed({
  get() {
    return (
      props.useCountryCode && code.value && codeCounts[code.value] > 1
    )
      ? `${code.value}-${countryCode.value}`
      : code.value
  },
  set(event) {
    code.value = (event?.split('-') || [])[0]
  }
})

const { value: phone, clearValue, el } = useMask((value) => {
  const mask = getPhoneMaskByPhoneCode(localCode.value ?? '1', countryCode.value)
  if (!mask) return ''
  if (typeof mask === 'string') return mask
  const phoneLength = Array.from(value.matchAll(/\d/g)).length
  // In case our phoneCode for the locale is an array of maskes with the different lengths, i.e. LU ['XXX - XXX', 'XXX - XXX - XXX'],
  // we want to default to the one with the longest length rather than the first item in the array.
  const mostXsItem = mask.reduce((maxItem, currentItem) => {
    const numXs = (currentItem.match(/X/g) || []).length
    return numXs > (maxItem.match(/X/g) || []).length ? currentItem : maxItem
  }, '')

  return mask.find((submask) => phoneLength === submask.match(/X/g)?.length) || mostXsItem
}, {
  regex: /\d/,
  onUpdate: (v) => model.value = v
})

watch(
  [model, el],
  (_value) => {
    if (el.value)
      clearValue.value = model.value
  },
  { immediate: true }
)
</script>
