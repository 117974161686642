<template>
  <client-only>
    <transition name="unfold">
      <lazy-cms-section
        v-if="showPromoBar && !disablePromoBarOnRoutes.includes(route.path) && megaMenu?.promoBar"
        :section="sections.promoBar"
        data-scroll-el="promo-bar"
      />
    </transition>
  </client-only>
  <div class="relative">
    <!-- Logo -->
    <base-link to="/" class="ml-4 mt-3 i-block lg:hidden" :class="{ 'absolute z-1': header.transparent }">
      <vf-logo :invert="header.transparent" />
    </base-link>
  </div>
  <div class="z-above-header" :class="{ 'sticky top-0': !isSomethingElseSticky }" data-scroll-el="header">
    <header
      ref="headerRef"
      class="pointer-within inset-x-0 bottom-0 my-6 flex center container duration <lg:fixed"
      :class="{
        'lg:absolute lg:top-0 lg:bottom-a': header.transparent,
        '<lg:rounded-full': !header.expanded,
      }"
      :style="`transform: translateY(${pxToRem(headerY)})`"
    >
      <div
        class="flex center rounded-lg duration-500 lg:grow lg:b lg:b-grey-10 lg:bg-black lg:px-3"
        :style="{ height: '3.75rem', transform: header.minimized ? 'translateY(7rem)' : '' }"
      >
        <!-- Logo -->
        <base-link to="/" class="shrink-0 px-3 <lg:hidden">
          <vf-logo variant="full" class="w-a" invert />
        </base-link>
        <div class="h-full flex grow items-center">
          <lazy-cms-section
            v-if="sections.megaMenu"
            class="dark h-full flex grow items-center c-white"
            class-wrapper="flex items-center"
            :section="sections.megaMenu"
          />
          <div
            class="flex center gap-4 transition-width duration lg:self-center lg:gap-2 <lg:overflow-hidden <lg:b-grey-10"
            :class="header.expanded ? '<lg:w-60 <lg:b <lg:bg-grey-10 <lg:p-2 <lg:rounded-lg' : '<lg:w-15'"
          >
            <vf-button
              v-if="!header.expanded"
              class="b-transparent rounded-full bg-brand-2 p-4 lg:hidden"
              :aria-label="$t.mobileNavigationExpand"
              @click="header.expanded = true"
            >
              <vf-icon name="dots" size="md" />
            </vf-button>

            <!-- Search -->
            <base-popover
              v-slot="{ close, opened, toggle }"
              :offset="20"
              :class="{ '<lg:hidden': !header.expanded }"
              strategy="fixed"
              @close="closeSearch"
            >
              <vf-button
                class="rounded-full p-2 <lg:bg-grey-10"
                :class="{ active: opened }"
                class-content="pointer-events-none"
                :aria-expanded="opened"
                :aria-haspopup="$viewport.lg ? 'menu' : undefined"
                :aria-label="$t.searchIconLabel"
                @keydown.esc="close"
                @click="togglePopover('search', $event, toggle, opened)"
              >
                <vf-icon name="search" size="md" />
              </vf-button>
              <vf-popover-content>
                <div style="width: 37.5rem">
                  <h2 class="mb-3 fw-bold">
                    {{ $t.searchPlaceholder }}
                  </h2>
                  <vf-search dialog @search="closeSearch" />
                </div>
              </vf-popover-content>
            </base-popover>

            <!-- Cart -->
            <vf-button
              to="/cart"
              class="relative rounded-full p-2 <lg:bg-grey-10"
              :class="{ '<lg:hidden': !header.expanded }"
              :aria-label="cart?.totalItems
                ? replaceAll($pluralize($t.cartWithItems, cart?.totalItems), { totalItems: cart?.totalItems })
                : $t.cart"
            >
              <vf-icon name="bag" size="md" />
              <client-only>
                <span
                  v-if="cart?.totalItems"
                  class="absolute bottom-0 right-0 h-4 w-4 flex select-none center b-2 b-grey-10 rounded-full bg-brand-2 c-white"
                  style="font-size: .5rem"
                  aria-hidden="true"
                >
                  {{ cart.totalItems }}
                </span>
              </client-only>
            </vf-button>

            <!-- Account -->
            <base-popover
              v-slot="{ close, opened, toggle }"
              :offset="20"
              :class="{ '<lg:hidden': !header.expanded }"
              strategy="fixed"
              @close="popoverOpened = false"
            >
              <vf-button
                class="rounded-full p-2 <lg:bg-grey-10"
                :class="{ active: opened }"
                class-content="pointer-events-none"
                :aria-expanded="opened"
                :aria-haspopup="$viewport.lg ? 'menu' : undefined"
                :aria-label="$t.myAccount"
                @keydown.esc="close"
                @click="togglePopover('account', $event, toggle, opened)"
              >
                <vf-icon name="account" size="md" />
              </vf-button>
              <vf-popover-content :max-height-offset="120">
                <h2 class="elevate-body-3 mb-10 fw-bold">
                  {{ $t.myAccount }}
                </h2>
                <account-authentication style="width: 21.44rem" />
              </vf-popover-content>
            </base-popover>

            <!-- Menu -->
            <vf-button
              v-if="header.expanded"
              class="rounded-full p-2 lg:hidden <lg:bg-grey-10"
              :aria-label="$t.menuIconLabel"
              @click="PanelMegaMenuMobile.open({ megaMenu, links })"
            >
              <vf-icon name="menu" size="md" />
            </vf-button>

            <vf-button
              v-if="showClearSessionButton && buyInStore.storeInfo?.storeId"
              class="<lg:hidden"
              size="tiny"
              variant="secondary-inverse"
              @click="buyInStore.clearSession"
            >
              {{ $t.clearSession }}
            </vf-button>
            <loyalty-rewards-preview-tile
              v-if="showLoyaltyRewardsInUtilityNav && profileStore.profile?.isLoyaltyMember"
              :close-on-leave="false"
              :open-on-hover="false"
              class="mx-2 <lg:hidden"
            />
          </div>
        </div>
        <div
          ref="menuTeleportRef"
          class="justify-self-end lg:hidden"
          style="max-width: 24.57rem"
          data-teleport="menu-buttons"
        />
      </div>
    </header>
  </div>
  <panel-account class="!px-0" class-content="!py-0 !w-full md:w-1/2" size="lg" />
  <panel-search class="!px-0" class-content="!py-0 <md:w-full" size="lg" />
  <panel-mega-menu-mobile class-content="!p-0 <md:w-full" size="lg" hide-close-button />
</template>

<script lang="ts" setup>
import { HeaderContextKey } from '#commerce/components/vf/header/context'
import { onDialogOpen } from '#core/utils/createDialog'

const { isSomethingElseSticky, links, megaMenu, sections, showPromoBar } = inject(HeaderContextKey)!

const { showClearSessionButton } = useAppConfig().checkout.buyInStore
const buyInStore = useBuyInStoreStore()
const cart = useCartStore()
const { disablePromoBarOnRoutes, showLoyaltyRewardsInUtilityNav } = useFeatureFlags()
const header = useHeaderStore()
const { hook, $viewport } = useNuxtApp()
const profileStore = useProfileStore()
const route = useRoute()
const { y } = useScroll(document)
const menuTeleportRef = useState<HTMLElement>('menuTeleportRef')
const PanelMegaMenuMobile = createPanel('mega-menu-mobile')
const PanelAccount = createPanel('account')
const PanelSearch = createPanel('search')

const panels = {
  menu: PanelMegaMenuMobile,
  account: PanelAccount,
  search: PanelSearch
}

const headerRef = toRef(header.refs, 'header')
const headerY = ref(0)
const popoverOpened = ref(false)

const togglePopover = (type: 'account' | 'search' | 'menu', e: Event, toggle: (e: Event) => void, closed: boolean) => {
  popoverOpened.value = !closed
  if ($viewport.lg) toggle(e)
  else panels[type]?.open({} as any, { hideCloseButton: true })
}

const closeSearch = () => {
  popoverOpened.value = false
}

watch(y, (newY: number, oldY: number) => {
  headerY.value = newY > oldY && newY > header.height.full - 25 && $viewport.lg && !popoverOpened.value ? -125 : 0
})

onMounted(() => {
  onDialogOpen(() => {
    if ($viewport.breakpoint !== 'lg') header.minimized = true
  })
  onDialogClose(() => {
    header.minimized = false
  })
})

const clear = hook('link:clicked', closeSearch)
onUnmounted(clear)
</script>
