<template>
  <div
    ref="elementRef"
    v-style:h="{ sm: `calc(100svh - ${pxToRem(header.height[header.transparent ? 'promoBar' : 'full'])})` }"
    class="relative grid"
    @click="handlePromotionClick"
  >
    <div class="relative grid-area-stack" style="height: inherit">
      <base-picture
        v-if="responsiveMedia.images"
        v-bind="responsiveMedia.images"
        :lazy
        fit="cover"
        :props-img="{ class: 'h-full' }"
        class="full"
      />
      <base-video
        v-if="responsiveMedia.video"
        v-bind="responsiveMedia.video"
        ref="videoRef"
        fit="cover"
        class="full"
        :autoplay="!lazy"
        :controls="false"
        loop
      />
    </div>
    <div
      v-style:p="{ sm: '7.5rem 1rem 6.5rem', lg: '7.5rem 2.5rem 2.5rem' }"
      class="relative mt-a w-full grid-area-stack <lg:text-center"
      style="background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8))"
    >
      <h1 v-if="title" class="elevate-title-2 c-white">
        {{ title }}
      </h1>
      <p v-if="subtitle" class="elevate-body-3 lg:elevate-body-2 mt-4 c-white">
        {{ subtitle }}
      </p>
      <div
        v-if="linkType !== 'No-CTA' && targets.length"
        class="gap-4 i-flex not-first:mt-6"
      >
        <div
          v-style="equalTargetStyle"
          class="gap-4"
          :class="isEqualWidthButtons ? 'grid' : 'flex wrap'"
        >
          <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" :size />
        </div>
      </div>
      <button
        v-if="videoRef"
        :aria-label="paused ? $t.videoPlay : $t.videoPause"
        class="absolute bottom-0 right-0 mb-10 mr-4 p-2 c-white duration lg:mb-14 lg:mr-10 @hactive:c-grey-60"
        @click="togglePlayback"
      >
        <vf-icon :name="paused ? 'play' : 'pause'" size="md" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { HeroContent } from '#types/components/cms/hero'

const { content } = defineProps<{
  content: HeroContent
}>()

const {
  equalTargetSize,
  linkType,
  media,
  promotionTracking,
  subtitle,
  targets = [],
  title
} = content

const { size } = useAppConfig().components.cms.sharedButton
const { getMedia } = useCms()
const header = useHeaderStore()
const { elementRef, handlePromotionClick } = usePromotionTracking(promotionTracking, content.name)

const lazy = inject(LazyMedia)
const responsiveMedia = getMedia(media)
const paused = ref(false)
const videoRef = ref()

const togglePlayback = () => {
  paused.value ? videoRef.value?.$el.play() : videoRef.value?.$el.pause()
  paused.value = !paused.value
}

const isEqualWidthButtons = getValueForBreakpoint('sm', equalTargetSize)

const equalTargetStyle = {
  ...(isEqualWidthButtons && {
    'grid-cols': {
      sm: '1fr',
      md: `repeat(${targets.length}, minmax(0,1fr))`
    }
  })
}

onMounted(() => videoRef.value?.play())
</script>
