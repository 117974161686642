import type { RecursivePartial } from '#types/utils'
import type { DialogConfig } from '#types/config/components/dialog'

export default {
  brandClasses: {
    articles: 'pb-8 px-4',
    bonus: 'text-sm uppercase fw-bold',
    header: {
      separator: 'b-l pl-1 b-grey-10',
      close: 'left-0 !my-0 right-unset'
    },
    item: 'line-clamp-3 text-sm fw-bold'
  },
} satisfies RecursivePartial<DialogConfig['miniCart']>
