<template>
  <base-form
    :form="form"
    name="newsletter"
    class="f-col"
    data-test-id="form-newsletter"
    @submit="submit"
  >
    <h2
      class="!subtitle-3 title-3 md:text-center"
      :class="classHeading"
      data-test-id="form-newsletter-title"
    >
      {{ $t.newsletterHeading }}
    </h2>
    <p
      v-if="$t.newsletterSubheading"
      class="mt-2 md:text-center "
      :class="classSubheading"
      data-test-id="form-newsletter-subtitle"
    >
      {{ $t.newsletterSubheading }}
    </p>
    <vf-notification
      v-if="notification.type"
      :type="notification.type"
      :dismissible="notificationsDismissible"
      class="mt-4"
      @close="dismissNotification"
    >
      {{ notification.message }}
    </vf-notification>
    <vf-form-field
      v-slot="{ attrs }"
      name="email"
      :variant
      :rule="[validateRequired($t.newsletterEmailAddress), validateEmail()]"
      :hint="$t.emailFormatHint"
    >
      <vf-input
        v-model="form.email"
        v-bind="attrs"
        type="email"
        :variant
        :disabled="loading"
        required
        class="mt-4"
      >
        {{ $t.newsletterEmailAddress }}
      </vf-input>
    </vf-form-field>
    <vf-form-field
      v-slot="{ attrs }"
      name="policy"
      :variant
      :rule="validateRequired('', $t.validators.requiredCheckbox)"
    >
      <vf-checkbox
        v-model="form.policy"
        v-bind="attrs"
        :size="checkboxSize"
        :variant
        :disabled="loading"
        required
        class="mt-6 md:mt-4"
      >
        <span  data-test-id="form-newsletter-text">
          <form-legal-disclaimer
            :content="$t.newsletterPolicy"
            :data-test-ids="{ 'brand-terms': 'form-newsletter-link', 'privacy': 'form-newsletter-link' }"
          />
        </span>
      </vf-checkbox>
    </vf-form-field>
    <vf-button
      type="submit"
      :variant="variant ? 'primary-inverse' : 'primary'"
      :loading="loading"
      :disabled="loading"
      class="mt-6 md:mt-4 md:self-center"
      :class="classSubmit"
    >
      {{ $t.newsletterSubmit }}
    </vf-button>
  </base-form>
</template>

<script lang="ts" setup>
import type { CSSClass } from '#types/common'
import type { FormLocation } from '#types/gtm'
import type { BaseNotification } from '#types/notification'

const { formLocation = 'inline:footer:none', notificationsDismissible } = defineProps<{
  formLocation?: FormLocation
  variant?: 'inverse'
  notificationsDismissible?: boolean
  classHeading?: CSSClass
  classSubheading?: CSSClass
  classSubmit?: CSSClass
}>()

const { checkboxSize } = useAppConfig().components.vf.footer.newsletter
const { validateEmail, validateRequired } = useLocalizedValidators()
const { $gtm, $t } = useNuxtApp()
const subscriptions = useSubscriptions()

const form = reactive({
  email: '',
  policy: false
})

const notification = reactive<BaseNotification>({
  type: '',
  message: ''
})

const loading = ref(false)

onMounted(() => $gtm.push('newsletterForm.onImpression', formLocation))

const dismissNotification = () => {
  notification.type = ''
  notification.message = ''
}

async function submit() {
  try {
    loading.value = true
    notification.type = ''
    $gtm.push('newsletterForm.onSubmit', formLocation)
    const consumerId = await subscriptions.newsletter({ optIn: true, email: form.email, acquisitionType: 'Signup' })
    $gtm.push('user.onLoadUserData', await getUserEventData(form.email, consumerId))

    form.policy = false
    form.email = ''

    notification.type = 'success'
    notification.message = $t.newsletterSuccess

    $gtm.push('newsletterForm.onConfirmed', formLocation)
  }
  catch (e) {
    if (e instanceof Error) {
      notification.type = 'error'
      notification.message = e.message
    }
  }
  finally {
    loading.value = false
  }
}
</script>
