<template>
  <div
    class="px-4 py-10 md:flex lg:px-10"
    :style="`background: ${config?.mainBg}`"
    data-test-id="collection-list-elevate"
  >
    <div class="flex items-center md:w-1/2">
      <div>
        <h2 class="elevate-title-4" :style="`color: ${config?.title}`">
          {{ title }}
        </h2>
        <ul class="mt-4 space-y-4">
          <li v-for="(item, i) in items" :key="i">
            <base-link
              :to="item.mediaTarget.url"
              class="elevate-title-2 py-1"
              @focus="activeIndex = i"
              @mouseover="activeIndex = i"
            >
              <span
                v-style="{
                  bgv: `linear-gradient(
                  to right,
                  ${config?.hover},
                  ${config?.hover} 50%,
                  ${config?.textFilled} 50%
                )`,
                }"
                :style="{
                  'background-size': '200% 100%',
                  'background-position': activeIndex === i ? '0' : '-100%',
                  'background-clip': 'text',
                  '-webkit-text-fill-color': 'transparent',
                }"
                class="duration <md:hidden !hover:bg-left"
              >
                {{ item.title }}
              </span>
              <span class="md:hidden" :style="`color: ${config?.text}`">
                {{ item.title }}
              </span>
            </base-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="w-1/2 flex justify-center <md:hidden">
      <base-link
        :to="items[activeIndex].mediaTarget.url"
        aria-hidden="true"
        tabindex="-1"
        class="relative i-block"
      >
        <base-picture v-bind="getMedia(items[0].media).images" class="op-0" />
        <transition name="fade" class="duration-500">
          <base-picture
            v-bind="getMedia(items[activeIndex].media).images"
            :key="activeIndex"
            class="absolute-0 overflow-hidden rounded-lg"
          />
        </transition>
      </base-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import componentTypeConfig from './config'
import type { CollectionContent } from '#types/components/cms/collection'

const { content } = defineProps<{ content: CollectionContent<any> }>()
const { title, name } = content
const { getMedia } = useCms()
const { itemsProp, contextColors } = componentTypeConfig[content.type!] || {}

// TO DO: These colors should come as part of content object from CMS
const config = contextColors?.[name?.includes('[holiday]') ? 'holiday' : 'standard']

const items = (itemsProp ? content[itemsProp] : content.items) || []

const activeIndex = ref(0)
</script>
