<template>
  <base-carousel
    ref="carousel"
    v-bind="$props"
    data-test-id="vf-carousel"
    @init="$emit('init', $event)"
    @to-prev="$emit('to-prev', $event)"
    @to-next="$emit('to-next', $event)"
    @paginate="$emit('paginate', $event)"
    @scroll-start="$emit('scroll-start', $event)"
    @scroll-end="$emit('scroll-end', $event)"
  >
    <template #prev="{ active, action }">
      <!-- @slot Slot for handler that moves content to the previous portion -->
      <slot name="prev" v-bind="{ active, action }">
        <base-button
          class="absolute z-2 flex "
          :class="[
            (!loop || !scrollable) && !active && ['pointer-events-none', brandClasses.controlsInactive],
            dir ? 'inset-x-0 justify-center my-4 top-0' : 'top-1/2 transform -translate-y-1/2 left-0',
            classControls,
          ]"
          :style="styleControls"
          :aria-label="$t.previous"
          data-test-id="vf-carousel-arrow-left"
          @click.stop="action"
        >
          <vf-icon name="chevron" :size="sizeControls || controlsSize || 'lg'" :dir="dir ? 'up' : 'left'" />
        </base-button>
      </slot>
    </template>

    <template #default="data">
      <!-- @slot Slot for list of items -->
      <slot v-bind="data" />
    </template>

    <template #next="{ active, action }">
      <!-- @slot Slot for handler that moves content to the next portion -->
      <slot name="next" v-bind="{ active, action }">
        <base-button
          class="absolute z-2 flex "
          :class="[
            (!loop || !scrollable) && !active && ['pointer-events-none', brandClasses.controlsInactive],
            dir ? 'inset-x-0 justify-center my-4 bottom-0' : 'top-1/2 transform -translate-y-1/2 right-0 justify-end',
            classControls,
          ]"
          :style="styleControls"
          :aria-label="$t.next"
          data-test-id="vf-carousel-arrow-right"
          @click.stop="action"
        >
          <vf-icon name="chevron" :size="sizeControls || controlsSize || 'lg'" :dir="dir ? 'down' : 'right'" />
        </base-button>
      </slot>
    </template>

    <template #pagination="data">
      <!-- @slot Slot for pagination handlers -->
      <slot name="pagination" v-bind="data" />
    </template>

    <template #bottom>
      <slot name="bottom" />
    </template>
  </base-carousel>
</template>

<script lang="ts" setup>
import type { StyleValue } from 'vue'
import type { BaseCarousel as BaseCarouselType } from '#components'
import type { CSSClass } from '#types/common'
import type { IconSizes } from '#types/sizes'

withDefaults(defineProps<{
  /**
   * Defines autoplay mode.
   * If true slides change automatically
   */
  autoplay?: boolean
  /**
   * Defines interval for slides changing
   * Relevant if `autoplay` prop provided only
   */
  interval?: number
  /**
   * Defines arrows visibility
   * If true arrows become always visible and that makes possible to scroll infinitely
   */
  loop?: boolean
  /**
   * Defines carousel direction
   */
  dir?: 'vertical'
  /**
   * Defines HTML tag of items container
   */
  tag?: string
  /**
   * Defines classList of items container
   */
  classContainer?: CSSClass
  /**
   * Defines styles of items container
   */
  styleContainer?: StyleValue
  /**
   * Defines classList of all content's wrapper excluding pagination section
   */
  classWrapper?: CSSClass
  /**
   * Defines styles of all content's wrapper excluding pagination section
   */
  styleWrapper?: StyleValue
  /**
   * Defines classList of controls
   */
  classControls?: CSSClass
  /**
   * Defines styles of controls container
   */
  styleControls?: StyleValue
  /**
   * Defines size of controls icon
   */
  sizeControls?: IconSizes
  /**
   * Defines enablement of using resize observer
   */
  observeResize?: boolean
}>(), {
  observeResize: false
})
defineEmits<{
  /**
   * Emits when BaseScroll component inits
   */
  (e: 'init', payload): void
  /**
   * Emits when content moves to prev portion
   */
  (e: 'to-prev', payload): void
  /**
   * Emits when content moves to next portion
   */
  (e: 'to-next', payload): void
  /**
   * Emits when content moves by clicking on pagination
   */
  (e: 'paginate', payload): void
  /**
   * Emits when scrolling starts
   */
  (e: 'scroll-start', payload): void
  /**
   * Emits when scrolling ends
   */
  (e: 'scroll-end', payload): void
}>()
defineSlots<{
  prev: (props: { active: boolean, action: () => void }) => void
  default: (props: { active: number, activeItem: number, action: (i: number) => void }) => void
  next: (props: { active: boolean, action: () => void }) => void
  pagination: (props: { active: number, activeItem: number, action: (i: number) => void, pages: number }) => void
  bottom: () => void
}>()

const { brandClasses, controlsSize } = useAppConfig().components.vf.carousel
const carousel = ref<InstanceType<typeof BaseCarouselType>>()
const scrollable = computed(() => carousel.value?.scroll?.hasNext || carousel.value?.scroll?.hasPrev)

defineExpose({
  el: computed(() => carousel.value?.el),
  scroll: computed(() => carousel.value?.scroll),
  scrollable
})
</script>
